<template>
    <div class="liefeng-information">
        <!--视频-->
        <div class="information-item" v-if="data && (data.dataType == 'video' || ((!data.dataType || data.dataType == 'auto') && data.video && data.video.length > 0))">
            <div class="text-video-title">
                <div class="text-title" v-html="data.title" @click="click(data)"></div>
                <div class="video-title">
                    <video :id="id" class="video" :poster="data.images && data.images.length > 0 ? data.images[0] : ''" :title="data.title" :src="data.video[0]" v-if="showVideo" />
                    <div v-else>
                        <img class="video" :src="data.images && data.images.length > 0 ? data.images[0] : ''" @click="playVideo" />
                        <div class="play liefeng-icon liefeng-icon-bofang" @click="playVideo"></div>
                    </div>
                </div>
            </div>
            <div class="desc" @click="click(data)">
                <div class="item">{{ data.from }}</div>
                <div class="item">{{ data.like }}</div>
                <div class="item">{{ getTime(data.time) }}</div>
            </div>
        </div>
        <!--单图片-->
        <div class="information-item" @click="click(data)" v-else-if="data && (data.dataType == 'image' || ((!data.dataType || data.dataType == 'auto') && data.images && data.images.length > 0 && data.images.length < 3))">
            <div class="text-pic-title">
                <div class="text-title" v-html="data.title"></div>
                <div class="pic-title"><img :src="data.images[0]" class="img" /></div>
            </div>
            <div class="desc">
                <div class="item">{{ data.from }}</div>
                <div class="item">{{ data.like }}</div>
                <div class="item">{{ getTime(data.time) }}</div>
            </div>
        </div>
        <!--多图片-->
        <div class="information-item" @click="click(data)" v-else-if="data && (data.dataType == 'pictures' || ((!data.dataType || data.dataType == 'auto') && data.images && data.images.length >= 3))">
            <div class="text-pic-3-title">
                <div class="text-title" v-html="data.title"></div>
                <div class="pic-title">
                    <img v-for="(img, index) in data.images.slice(0, 3)" :key="'img_' + index" :src="img" class="img" />
                </div>
            </div>
            <div class="desc">
                <div class="item">{{ data.from }}</div>
                <div class="item">{{ data.like }}</div>
                <div class="item">{{ getTime(data.time) }}</div>
            </div>
        </div>

        <!--纯文本-->
        <div class="information-item" @click="click(data)" v-else-if="data && data.title">
            <div class="text-title" v-html="data.title"></div>
            <div class="desc">
                <div class="item">{{ data.from }}</div>
                <div class="item">{{ data.like }}</div>
                <div class="item">{{ getTime(data.time) }}</div>
            </div>
        </div>

        <div class="loading">
            加载中...
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        data: {
            //Banner数组
            type: Object,
            default: () => {
                return {
                    // dataType:"auto",//text纯文本，image单图片，pictures多图片，video视频，auto或null 自动检测类型
                    // images:[],//图片地址，数组，当type=auto时，images为null或[]是纯文本模式，images.length<3是单图片，images.length>=3是多图片模式
                    // video:[],//视频地址，数组，当type=auto时，优先于images
                    // title:"",//标题
                    // from:"",//来源于哪个栏目
                    // like:"",//点赞
                    // time:1628828110111,//时间
                    // url:"",//跳转地址
                }
            },
        },
    },
    data() {
        return {
            id: this.$core.createID(),
            showVideo: false,
        }
    },
    watch: {
        data: {
            handler(item, oldItem) {
                if (JSON.stringify(item) !== JSON.stringify(oldItem)) {
                    if (this.data.like != undefined) this.data.like = this.data.like + "赞"
                    if (this.data.praiseNum != undefined) this.data.like = this.data.praiseNum + "赞"
                    if (this.data.video && typeof this.data.video == "string") this.data.video = this.data.video.split("?")
                    if (this.data.images && typeof this.data.images == "string") this.data.images = this.data.images.split("?")
                    if (this.data.participationNum || this.data.readNum || this.data.participationNum >= 0 || this.data.readNum >= 0)
                        this.data.like = this.data.participationNum || this.data.participationNum >= 0 ? this.data.participationNum + "参与" : this.data.readNum || this.data.readNum >= 0 ? this.data.readNum + "阅读" : ""
                    if (this.data.images && this.data.images.length > 0 && this.data.images.length < 3 && this.data.from && this.data.from.length > 10)
                        // console.log(this.data.like);
                        this.data.from = this.data.from.substring(0, 8) + "..."
                    if (!item.photo) {
                        // this.data.images = ""
                    }
                }
            },
            immediate: true,
        },
    },
    created() {},
    methods: {
        click(item) {
            var params = {}
            params.url = item.url
            params.jumpJson = item.jumpJson
            if (!params.url && (!params.jumpJson || JSON.stringify(params.jumpJson) == "{}")) {
                var newParams = this.analysisRedirectToFun(item)
                params.url = newParams.url
                params.jumpJson = newParams.jumpJson
            }
            this.$emit("click", params)
        },
        getTime(time) {
            // console.log(time);
            var timestr = ""
            if (time > 1000000) {
                var min = new Date().getTime() - time
                min = Math.ceil(min / (1000 * 60))
                if (min < 10) {
                    timestr = "刚刚"
                } else if (min >= 10 && min < 60) {
                    timestr = min + "分钟前"
                } else if (min >= 60 && min < 60 * 24) {
                    var hour = Math.ceil(min / 60)
                    timestr = hour + "小时前"
                } else if (min >= 60 * 24 && min < 60 * 24 * 30) {
                    var day = Math.ceil(min / (60 * 24))
                    timestr = day + "天前"
                } else if (min >= 60 * 24 * 30 && min < 60 * 24 * 365) {
                    var day = Math.ceil(min / (60 * 24 * 30))
                    timestr = day + "月前"
                } else if (min >= 60 * 24 * 365) {
                    var day = Math.ceil(min / (60 * 24 * 365))
                    timestr = day + "年前"
                }
            }
            return timestr
        },
        playVideo() {
            this.showVideo = true
            this.$nextTick(res => {
                // Taro.createVideoContext(this.id).play()
            })
        },

        analysisRedirectToFun: function(obj) {
            var params = { jumpJson: null, url: null }
            switch (obj.functionType) {
                // 图文,音视频，地图
                case "1":
                case "2":
                case "3":
                case "5":
                    params.url = `/apps/suiyue/pages/information/sharingdetails/details?code=${obj.id || obj.informationId}&functionType=${obj.functionType}`
                    break
                // 商家
                case "4":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: "/pages/index/jump?url=/indexPages/businessDetail/index&id=" + obj.id || obj.informationId + "&relunch=true" }
                    break
                // 地图类
                // 议事公告
                case "7":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: "/pages/index/jump?url=/spacePages/issuesShow/issuesShow&id=" + obj.id || obj.informationId + "&relunch=true" }
                    break
                case "8": // 议事投票
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: "/pages/index/jump?url=/spacePages/issuesVote/issuesVote&id=" + obj.id || obj.informationId + "&relunch=true" }
                    break
                // 议事详情
                case "10":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: "/pages/index/jump?url=/spacePages/issuesDetail/issuesDetail&id=" + obj.id || obj.informationId + "&relunch=true" }
                    break
                // 议事讨论
                case "12":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: "/pages/index/jump?url=/spacePages/issuesDiscuss/issuesDiscuss&id=" + obj.id || obj.informationId + "&title=" + (obj.title || "议事讨论") + "&relunch=true" }
                    break
                // 直播
                case "15":
                    params.url = `/apps/suiyue/pages/information/sharingdetails/details?code=${obj.id || obj.informationId}&functionType=${obj.functionType}`
                    break
                // 常规投票
                case "16":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: "/pages/index/jump?url=/indexPages/votePages/pages/voteList/index&id=" + obj.id || obj.informationId + "&title=" + obj.title + "&relunch=true" }
                    break
                // 答题活动
                case "17":
                    // let params = {
                    //     FunctionType: obj.functionType,
                    //     i:obj.informationId,
                    //     t:obj.functionType,
                    //     ...obj,
                    // }
                    // Taro.navigateTo({
                    //     url: `/apps/suiyue/pages/information/correct/details/details?i=${obj.informationId}&t=${obj.functionType}`,
                    // })
                    params.url = `/apps/suiyue/pages/information/correct/details/details?i=${obj.informationId}&t=${obj.functionType}`
                    break
                // 预约活动
                case "24":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: `/pages/index/jump?url=/mainTaskPages/modules/activity/pages/detail/index&code=${obj.code || obj.infoCode}&relunch=true` }
                    // params.url= `/apps/suiyue/pages/information/active/detail?code=${obj.code}`
                    break
                // 问卷采集/服务
                case "25":
                    params.jumpJson = { type: "weapp", appid: "gh_6fc4ee9da3bb", url: `/pages/index/jump?url=/mainTaskPages/modules/infoCollection/pages/detail/index&code=${obj.code || obj.infoCode}&relunch=true` }
                    // params.url=  `/apps/suiyue/pages/information/questionnaire/detail?code=${obj.code}`
                    break
                //  信息详情
                default:
                    params.url = `/apps/suiyue/pages/information/details/details?code=${obj.id || obj.infoCode || obj.informationId}`
                    break
            }
            return params
        },
    },
}
</script>
<style lang="less">
.liefeng-information {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .information-item {
        display: flex;
        flex-direction: column;
        width: calc(100% - 20px);
        margin: 0px 10px;
        padding: 8px 0px;
        border-bottom: 1px solid #eee;
        .desc {
            display: flex;
            font-size: 12px;
            line-height: 1.5;
            color: #888;
            padding-top: 2px;
            .item {
                padding-right: 10px;
            }
        }
        .text-title {
            color: #333;
            font-size: 17px;
            line-height: 1.3;
            white-space: normal;
            letter-spacing: 1px;
            word-break: break-all;
        }
        .text-pic-title {
            display: flex;
            color: #333;
            font-size: 17px;
            line-height: 1.3;
            white-space: normal;
            letter-spacing: 1px;
            .text-title {
                width: calc(100% - 140px);
                margin-right: 5px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
            .pic-title {
                width: 130px;
                height: 80px;
                overflow: hidden;
                border-radius: 2px;
                margin-bottom: -17px;
                border-radius: 2px;
                .img {
                    width: 100%;
                    height: auto;
                    // min-height:160px;
                    border-radius: 2px;
                    background: #f5f5f5;
                    box-shadow: 1px 1px 5px #eee;
                }
            }
        }
        .text-pic-3-title {
            display: flex;
            flex-direction: column;
            color: #333;
            font-size: 17px;
            line-height: 1.3;
            white-space: normal;
            letter-spacing: 1px;
            .text-title {
                color: #333;
                font-size: 17px;
                line-height: 1.3;
                white-space: normal;
                letter-spacing: 1px;
            }
            .pic-title {
                display: flex;
                justify-content: space-between;
                // flex-wrap: wrap;
                // align-items: flex-start;
                width: 100%;
                overflow: hidden;
                margin: 5px 0;
                max-height: 100px;
                border-radius: 2px;
                .img {
                    width: 32%;
                    height: auto;
                    border-radius: 2px;
                    background: #f5f5f5;
                    box-shadow: 1px 1px 5px #eee;
                }
            }
        }
        .text-video-title {
            display: flex;
            flex-direction: column;
            color: #333;
            font-size: 17px;
            line-height: 1.3;
            white-space: normal;
            letter-spacing: 1px;
            .text-title {
                color: #333;
                font-size: 17px;
                line-height: 1.3;
                white-space: normal;
                letter-spacing: 1px;
            }
            .video-title {
                display: flex;
                flex-direction: column;

                width: 100%;
                overflow: hidden;
                margin: 5px 0;
                height: 200px;
                border-radius: 5px;
                box-shadow: 1px 1px 5px #eee;
                background: #f5f5f5;
                .video {
                    width: 100%;
                    height: 200px;
                }
                .play {
                    position: relative;
                    top: -120px;
                    left: calc(50% - 20px);
                    color: #eee;
                    font-size: 40px;
                    z-index: 1;
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
                }
            }
        }
    }

    .loading {
        text-align: center;
        font-size: 12px;
        margin: 50px 20px;
        color: #888;
        display: none;

        &:first-child {
            display: block;
        }
    }
}
</style>
